import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=33108921&"
import script from "./SideMenu.vue?vue&type=script&lang=js&"
export * from "./SideMenu.vue?vue&type=script&lang=js&"
import style0 from "./SideMenu.vue?vue&type=style&index=0&id=33108921&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33108921')) {
      api.createRecord('33108921', component.options)
    } else {
      api.reload('33108921', component.options)
    }
    module.hot.accept("./SideMenu.vue?vue&type=template&id=33108921&", function () {
      api.rerender('33108921', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/tc/SideMenu.vue"
export default component.exports