var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("../../../assets/DXA_Invest-logo.png"),
          alt: "logo",
        },
      }),
      _vm.display === _vm.displayEnum.SMALL
        ? _c("div", [_c("SideMenu")], 1)
        : _vm._e(),
      _vm.display !== _vm.displayEnum.SMALL
        ? _c(
            "div",
            { staticClass: "button-group" },
            [
              _c("HeaderMenuLink", {
                attrs: {
                  href: _vm.envUrl + "auth",
                  target: "_self",
                  text: "Entrar",
                  type: "outlined",
                },
              }),
              _c("HeaderMenuLink", {
                staticClass: "create-wallet",
                attrs: {
                  href:
                    _vm.envUrl +
                    "auth/register-client?partner=" +
                    _vm.tcPartnerName,
                  target: "_self",
                  text: "Criar Carteira",
                  type: "filled",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "container" }, [
      _c("section", { staticClass: "section1" }, [
        _c("div", { staticClass: "section1-content" }, [
          _vm._m(0),
          _c("p", { staticClass: "header-subtitle" }, [
            _vm._v(
              " Comece a investir em empresas privadas de forma guiada e segura. "
            ),
          ]),
          _c(
            "div",
            { staticClass: "redirect-button-container" },
            [
              _c("HeaderMenuLink", {
                staticClass: "link-filled-size",
                attrs: {
                  href:
                    _vm.envUrl +
                    "auth/register-client?partner=" +
                    _vm.tcPartnerName,
                  target: "_self",
                  text: "Conheça nossas oportunidades",
                  type: "filled",
                },
              }),
            ],
            1
          ),
          _vm._m(1),
          _vm._m(2),
        ]),
        _vm._m(3),
      ]),
      _vm._m(4),
      _c("section", { staticClass: "section3" }, [
        _c("div", { staticClass: "embed-video-container" }, [
          _c("iframe", {
            style:
              "width: " +
              _vm.embedVideoWidth +
              "px; height: " +
              _vm.embedVideoHeight +
              "px;",
            attrs: {
              src: "https://player.vimeo.com/video/674535050",
              frameborder: "0",
              allowfullscreen: "",
            },
          }),
          _c("div", { staticClass: "how-it-works-container" }, [
            _c("span", { staticClass: "how-it-works" }, [
              _vm._v("Como funciona?"),
            ]),
            _vm._m(5),
            _c(
              "div",
              { staticClass: "redirect-button-container" },
              [
                _c("HeaderMenuLink", {
                  staticClass: "link-filled-size",
                  attrs: {
                    href:
                      _vm.envUrl +
                      "auth/register-client?partner=" +
                      _vm.tcPartnerName,
                    target: "_self",
                    text: "Crie sua Carteira Alpha",
                    type: "filled",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "section4" }, [
        _c("span", { staticClass: "steps-label" }, [_vm._v("Passo a passo")]),
        _vm._m(6),
        _c(
          "div",
          { staticClass: "redirect-footer-container" },
          [
            _c("HeaderMenuLink", {
              staticClass: "link-filled-size",
              attrs: {
                href:
                  _vm.envUrl +
                  "auth/register-client?partner=" +
                  _vm.tcPartnerName,
                target: "_self",
                text: "Crie sua Carteira Alpha",
                type: "filled",
              },
            }),
          ],
          1
        ),
      ]),
      _c("footer", { staticClass: "footer" }, [
        _vm._m(7),
        _c(
          "span",
          { staticClass: "footer-c" },
          [
            _vm._v("© DXA Invest 2022• Todos os direitos reservados • "),
            _c("DxaLink", {
              attrs: {
                href: "https://www.dxainvest.com/legal/politica-de-privacidade",
                target: "_self",
                text: "Política de Privacidade",
                size: "small",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-text" }, [
      _c("span", [_vm._v("Invista nas")]),
      _c("span", { staticClass: "primary-text" }, [
        _vm._v("melhores empresas fora da Bolsa"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row partner-row" }, [
      _c("img", {
        staticClass: "img",
        attrs: {
          src: require("../../../assets/tc-landing/tc-logo.svg"),
          alt: "img",
        },
      }),
      _c("span", { staticClass: "primary-text" }, [_vm._v("+")]),
      _c("img", {
        staticClass: "img",
        attrs: {
          src: require("../../../assets/tc-landing/logo-dxa.svg"),
          alt: "img",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "partner-footer-content" }, [
      _c("span", { staticClass: "primary-text partner-footer" }, [
        _vm._v("Parceiro Oficial de Private Equity e Venture Capital"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-container" }, [
      _c("div", { staticClass: "header-label" }, [
        _c("h2", { staticClass: "card-header-title" }, [
          _vm._v(" O mercado que supera a bolsa de valores há 20 anos "),
        ]),
        _c("div", { staticClass: "card-header-text" }, [
          _c("p", [
            _vm._v(
              " Investimentos em private equity tem uma performance histórica quase "
            ),
            _c("span", { staticClass: "card-header-text-bold" }, [
              _vm._v("4x maior"),
            ]),
            _vm._v(" que as 500 melhores ações da bolsa americana. "),
          ]),
        ]),
        _c("div", { staticClass: "graphic-container" }, [
          _c("img", {
            attrs: {
              src: require("../../../assets/landing-graphic.svg"),
              alt: "Gráfico",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "section2" }, [
      _c("div", { staticClass: "section2-content" }, [
        _c("img", {
          staticClass: "img-section2",
          attrs: {
            src: require("../../../assets/tc-landing/xx.png"),
            alt: "img",
          },
        }),
        _c("div", { staticClass: "number-group" }, [
          _c("div", { staticClass: "number-container" }, [
            _c("span", { staticClass: "number" }, [_vm._v("+R$ 1Bilhão")]),
            _c("span", { staticClass: "number-label" }, [
              _vm._v(" sob gestão "),
            ]),
          ]),
          _c("div", { staticClass: "number-container" }, [
            _c("span", { staticClass: "number" }, [_vm._v("90%")]),
            _c("span", { staticClass: "number-label" }, [
              _vm._v(" de investidores globais "),
            ]),
          ]),
          _c("div", { staticClass: "number-container" }, [
            _c("span", { staticClass: "number" }, [_vm._v("R$ 50k")]),
            _c("span", { staticClass: "number-label" }, [
              _vm._v(" aporte mínimo "),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "how-it-works-label" }, [
      _vm._v(" A "),
      _c("span", { staticClass: "primary-text" }, [_vm._v(" DXA Invest ")]),
      _vm._v(" oferece um programa de investimento com uma "),
      _c("span", { staticClass: "primary-text" }, [
        _vm._v(" gestão completamente exclusiva e personalizada"),
      ]),
      _vm._v(
        ", visando oferecer as oportunidades que melhor se adequam ao perfil mapeado do investidor. "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "steps-row" }, [
      _c("div", { staticClass: "step-container" }, [
        _c("div", { staticClass: "step-number-container" }, [
          _c("span", { staticClass: "step-number" }, [_vm._v("1")]),
        ]),
        _c("span", { staticClass: "step-label" }, [
          _vm._v(" Crie sua Carteira Alpha "),
        ]),
        _c("span", { staticClass: "step-description" }, [
          _vm._v(
            "Escolha o quanto deseja investir em um ano para definir o nível da sua Carteira Alpha."
          ),
        ]),
      ]),
      _c("div", { staticClass: "step-container" }, [
        _c("div", { staticClass: "step-number-container" }, [
          _c("span", { staticClass: "step-number" }, [_vm._v("2")]),
        ]),
        _c("span", { staticClass: "step-label" }, [
          _vm._v(" Faça seu primeiro check-in "),
        ]),
        _c("span", { staticClass: "step-description" }, [
          _vm._v(
            "Após criar sua carteira, você será apresentado ao seu gestor DXA exclusivo. Ele irá fazer o mapeamento do seu perfil de investimento e composição da sua Carteira Alpha. "
          ),
          _c("span", { staticClass: "step-description-break-line" }, [
            _vm._v(
              " É com base nessas informações que a DXA vai sugerir alocações em empresas privadas adequadas ao seu portfólio."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "step-container" }, [
        _c("div", { staticClass: "step-number-container" }, [
          _c("span", { staticClass: "step-number" }, [_vm._v("3")]),
        ]),
        _c("span", { staticClass: "step-label" }, [
          _vm._v(" Realize sua primeira alocação "),
        ]),
        _c("span", { staticClass: "step-description" }, [
          _vm._v(
            "Depois do seu primeiro mapeamento de perfil de investimento, seu gestor recomendará a primeira alocação da sua carteira. "
          ),
          _c("span", { staticClass: "step-description-break-line" }, [
            _vm._v(
              " Pela plataforma você poderá acompanhar o crescimento da empresa investida através de vídeos e relatórios preparados pelo nosso time de Execução."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-row" }, [
      _c("div", { staticClass: "footer-logos" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("../../../assets/DXA_Invest-logo.png"),
            alt: "logo",
          },
        }),
        _c(
          "a",
          {
            attrs: {
              href: "https://br.linkedin.com/company/dxainvest",
              target: "_self",
            },
          },
          [
            _c("img", {
              staticClass: "social-logo",
              attrs: {
                src: require("../../../assets/tc-landing/linkedin.svg"),
              },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "footer-label-containter" }, [
        _c("span", { staticClass: "footer-label" }, [
          _vm._v("A melhor forma de investir fora da bolsa."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }