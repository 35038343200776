var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Slide",
    { staticClass: "side-menu", attrs: { right: "" } },
    [
      _c("DxaLink", {
        attrs: {
          href: _vm.envUrl + "auth",
          target: "_blank",
          text: "Entrar",
          size: "large",
        },
      }),
      _c("DxaLink", {
        attrs: {
          href: _vm.envUrl + "auth/welcome-page?partner=" + _vm.tcPartnerName,
          target: "_blank",
          text: "Criar Carteira",
          size: "large",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }